import React from 'react';
import axios from 'axios';

class Contactme extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            subject: '',
            email: '',
            message: '',
            validateEmail: false
        }
    }
    onNameChange(e) {
        this.setState({
            name: e.target.value
        })
    }
    onSubjectChange(e) {
        this.setState({
            subject: e.target.value
        })
    }
    onMessageChange(e) {
        this.setState({
            message: e.target.value
        })
    }
    onEmailChange(e) {
        let email = e.target.value;
        if (this.validateEmail(email)) {

            this.setState({
                email: e.target.value,
                validateEmail: true
            });
        } else {
            this.setState({
                email: e.target.value,
                validateEmail: false
            });
        }
    }
    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    resetForm() {
        this.setState({ name: '', email: '', subject: '', message: '', validateEmail:false });
    }
    onSubmit(e) {
        e.preventDefault();
        if(this.state.validateEmail){

            axios({
                method: "POST",
                url: "https://protected-lowlands-74292.herokuapp.com/emailme",
                data: JSON.parse(JSON.stringify(this.state))
            }).then((res) => {
                console.log(res)
                this.resetForm();
            });
        }else{
            alert('Emailid is invalid..!');
        }
    }
    render() {
        return (
            <div className="vg-page page-contact" id="contact">
                <div className="container-fluid">
                    <div className="text-center wow fadeInUp">
                        <div className="badge badge-subhead">Contact</div>
                    </div>
                    <h1 className="text-center fw-normal wow fadeInUp">Get in touch</h1>
                    <div className="row py-5">
                        <div className="col-lg-12">
                            <form className="vg-contact-form" onSubmit={(e) => { this.onSubmit(e) }}>
                                <div className="form-row">
                                    <div className="col-12 mt-3 wow fadeInUp">
                                        <input className="form-control" type="text" name="Name" value={this.state.name} placeholder="Your Name" onChange={(e) => { this.onNameChange(e) }} />
                                    </div>
                                    <div className="col-6 mt-3 wow fadeInUp">
                                        <input className="form-control" type="text" name="Email" value={this.state.email} placeholder="Email Address" onChange={(e) => { this.onEmailChange(e) }} />
                                    </div>
                                    <div className="col-6 mt-3 wow fadeInUp">
                                        <input className="form-control" type="text" name="Subject" value={this.state.subject} placeholder="Subject" onChange={(e) => { this.onSubjectChange(e) }} />
                                    </div>
                                    <div className="col-12 mt-3 wow fadeInUp">
                                        <textarea className="form-control" name="Message" rows="6" value={this.state.message} onChange={(e) => { this.onMessageChange(e) }}
                                            placeholder="Enter message here.."></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-theme mt-3 wow fadeInUp ml-1">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contactme;