import React from 'react';

class BackToTop extends React.Component {
    render() {
        return (
            <div class="btn-back_to_top">
                <span class="ti-arrow-up"></span>
            </div>
        )
    }
}

export default BackToTop;