import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <div class="vg-footer">
                <h1 class="text-center">KEEP SMILING</h1>
            </div>
        )
    }
}

export default Footer;