import React from 'react';

class Project extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardSelected: 3,
        }
    }

    onCardRadioSelect(event) {

        this.setState({
            cardSelected: parseInt(event.target.value),
        });
    }
    onCardSelect(event, id) {

        this.setState({
            cardSelected: id,
        });
    }
    render() {
        return (
            <div className="vg-page page-service" id="project">
                <div className="container">
                    <div className="text-center wow fadeInUp">
                        <div className="badge badge-subhead">Projects</div>
                    </div>
                    <h1 className="fw-normal text-center wow fadeInUp margin-bottom-40">Project and fun</h1>
                    <div className="row card-slider-container">
                        <input id='radio-1' type="radio" name="position" value='1' checked={this.state.cardSelected === 1} onChange={(e) => { this.onCardRadioSelect(e) }} />
                        <input id='radio-2' type="radio" name="position" value='2' checked={this.state.cardSelected === 2} onChange={(e) => { this.onCardRadioSelect(e) }} />
                        <input id='radio-3' type="radio" name="position" value='3' checked={this.state.cardSelected === 3} onChange={(e) => { this.onCardRadioSelect(e) }} />
                        <input id='radio-4' type="radio" name="position" value='4' checked={this.state.cardSelected === 4} onChange={(e) => { this.onCardRadioSelect(e) }} />
                        <input id='radio-5' type="radio" name="position" value='5' checked={this.state.cardSelected === 5} onChange={(e) => { this.onCardRadioSelect(e) }} />
                        <main id="carousel">
                            <div id='card-1' className="item card-shadow" onClick={(e) => { this.onCardSelect(e, 1) }}>
                                <div className="card set-card-height">
                                    <img id="quick-sort-project-image" className="card-img-top set-card-img-h" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">Quick sort simulation</h5>
                                        <p className="card-text">It's a simulation which simulate the quicksort algorithm</p>
                                        <a href="../project/quick_sort/quick_sort.html"><button className="btn btn-primary">Explore</button></a>
                                    </div>
                                </div>
                            </div>
                            <div id='card-2' className="item card-shadow" onClick={e => { this.onCardSelect(e, 2) }}>
                                <div className="card set-card-height">
                                    <img id="elephant-project-image" className="card-img-top set-card-img-h" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">Elephant by DFT</h5>
                                        <p className="card-text">Rendering an elephant using Discrete fourier tranformation.</p>
                                        <a href="../project/FF_elephant/DFT.html"><button className="btn btn-primary">Explore</button></a>
                                    </div>
                                </div>
                            </div>
                            <div id='card-3' className="item card-shadow" onClick={e => { this.onCardSelect(e, 3) }}>
                                <div className="card set-card-height">
                                    <img id="titanic-project-image" className="card-img-top set-card-img-h" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">Titanic prediction</h5>
                                        <p className="card-text">It's a predictive ml model that predicts whether you would survive the titanic accident or not...!</p>
                                        <a href="../project/titanic/titanic.html"><button className="btn btn-primary">Explore</button></a>
                                    </div>
                                </div>
                            </div>
                            <div id='card-4' className="item card-shadow" onClick={e => { this.onCardSelect(e, 4) }}>
                                <div className="card set-card-height">
                                    <img id="merge-sort-project-image" className="card-img-top set-card-img-h" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">Merge sort simulation</h5>
                                        <p className="card-text">It's a simulation which simulate the mergesort algorithm</p>
                                        <a href="../project/merge_sort/merge_sort.html"><button className="btn btn-primary">Explore</button></a>
                                    </div>
                                </div>
                            </div>
                            <div id='card-5' className="item card-shadow" onClick={e => { this.onCardSelect(e, 5) }}>
                                <div className="card set-card-height">
                                    <img id="tricolor-project-image" className="card-img-top set-card-img-h" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">Tricolor</h5>
                                        <p className="card-text">Rendering The Tricolor using Discrete fourier tranformation.</p>
                                        <a href="../project/FF_flag/DFT.html"><button className="btn btn-primary">Explore</button></a>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        )
    }
}

export default Project;