import React from 'react';
import Home from './components/Home';
import About from './components/About';
import Project from './components/Project';
// import Testimonial from './components/Testimonial';
// import CoursesAndCertificate from './components/CoursesAndCertificate';
// import GitAndStack from './components/GitAndStack';
import Contactme from './components/Contactme';
import Footer from './components/Footer';
import BackToTop from './components/BackToTop';

class App extends React.Component {
    render() {
        return (
            <div>
                <Home />
                <BackToTop />
                <About />
                <Project />
                {/* <Testimonial />
                <CoursesAndCertificate />
                <GitAndStack /> */}
                <Contactme />
                <Footer />
            </div>
        );
    }
}

export default App;