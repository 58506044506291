import React from "react";
import moment from "moment";

class About extends React.Component {
  render() {
    return (
      <div className="vg-page page-about" id="about">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-4 py-3">
              <div className="img-place wow fadeInUp">
                <img id="profile-pic" alt="" />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1 wow fadeInRight">
              <h1 className="fw-light">Dhruvin Modi</h1>
              <h5 className="fg-theme mb-3">Software Engineer</h5>
              <p className="text-muted">
                Hey there, I am computer geek. Always eager to lean new
                technologies. My simple philosophy is, "Keep Learning, Keep
                Coding, Keep Smiling".{" "}
              </p>
              <ul className="theme-list">
                <li>
                  <b>Lives In:</b> Ahmedabad, India
                </li>
                <li>
                  <b>Age:</b>
                  {moment().diff("1998-11-23", "years")}
                </li>
                <li>
                  <b>Gender:</b> Male
                </li>
                <li>
                  <b>Email-Id:</b>{" "}
                  <a href="mailto:dhruvin.developer@gmail.com">
                    dhruvin.developer@gmail.com
                  </a>
                </li>
              </ul>
              <a href="https://drive.google.com/file/d/1odKoUl34TilCXBMZTH6cXnBHmXlCK5MH/view?usp=sharing">
                <button className="btn btn-theme-outline">Download CV</button>
              </a>
            </div>
          </div>
        </div>
        <div id="experience" className="container py-5">
          <h1 className="text-center fw-normal wow fadeIn">
            Contributed products
          </h1>
          <div className="row d-flex justify-content-between">
            <div className="product-card">
              <div className="d-flex align-content-center justify-content-center product-card-logo-container">
                <img className="edukite-logo" alt="edukite-logo" />
              </div>
              <div className="product-title">Edukite Learning</div>
              <div className="product-description">
                Learning made fun and exciting
              </div>
            </div>
            <a href="https://freshflows.io/" target="blank" className="text-decoration-none">
              <div className="product-card">
                <div className="d-flex align-content-center justify-content-center product-card-logo-container">
                  <img className="freshflows-logo" alt="freshflows-logo" />
                </div>
                <div className="product-title">Freshflows</div>
                <div className="product-description">
                  Build Great Products with Distributed Teams
                </div>
              </div>
            </a>
            <a href="https://auzmor.com/hire/" target="blank" className="text-decoration-none">
              <div className="product-card">
                <div className="d-flex align-content-center justify-content-center product-card-logo-container">
                  <img className="auzmor-hire-logo" alt="auzmor-hire-logo" />
                </div>
                <div className="product-title">Auzmor Hire</div>
                <div className="product-description">
                Find your next great talent with Auzmor Hire
                </div>
              </div>
            </a>
            <a href="https://auzmor.com/auzmor-office/" target="blank" className="text-decoration-none">
              <div className="product-card">
                <div className="d-flex align-content-center justify-content-center product-card-logo-container">
                  <img className="auzmor-office-logo" alt="auzmor-office-logo" />
                </div>
                <div className="product-title">Auzmor Office</div>
                <div className="product-description">
                  Reimagine employee experience and engagement
                </div>
              </div>
            </a>
          </div>
          {/* <div className="row">
                        <div className="col-md-3 logo-container">
                            <div className="typescript-logo-container"></div>
                            <div className="logo-title">Typescript</div>
                        </div>
                        <div className="col-md-3 logo-container">
                            <div className="react-js-logo-container"></div>
                            <div className="logo-title">ReactJs</div>
                        </div>
                        <div className="col-md-3 logo-container">
                            <div className="redux-logo-container"></div>
                            <div className="logo-title">Redux</div>
                        </div>
                        <div className="col-md-3 logo-container">
                            <div className="redux-saga-logo-container"></div>
                            <div className="logo-title">Saga</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 logo-container">
                            <div className="js-logo-container"></div>
                            <div className="logo-title">Javascript</div>
                        </div>
                        <div className="col-md-3 logo-container">
                            <div className="node-js-logo-container"></div>
                            <div className="logo-title">NodeJs</div>
                        </div>
                        
                        <div className="col-md-3 logo-container">
                            <div className="mongo-logo-container"></div>
                            <div className="logo-title">MongoDB</div>
                        </div>
                        <div className="col-md-3 logo-container">
                            <div className="python-logo-container"></div>
                            <div className="logo-title">Python</div>
                        </div>
                    </div> */}
        </div>
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-6 wow fadeInRight">
              <h2 className="fw-normal">Education</h2>
              <ul className="timeline mt-4 pr-md-5">
                <li>
                  <div className="title">2021</div>
                  <div className="details">
                    <h5>GATE qualified</h5>
                    <small className="fg-theme">
                      Graduate Aptitude Test in Engineering
                    </small>
                    <p>I qualified GATE 2021 in Computer science.</p>
                  </div>
                </li>
                <li>
                  <div className="title">2020</div>
                  <div className="details">
                    <h5>Bachelore of Engineering in IT</h5>
                    <small className="fg-theme">
                      Gujarat Technological University
                    </small>
                    <p>Description: comming soon..!</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6 wow fadeInRight" data-wow-delay="200ms">
              <h2 className="fw-normal">Experience history</h2>
              <ul className="timeline mt-4 pr-md-5">
                <li>
                  <div className="title">2022 - Current</div>
                  <div className="details">
                    <h5>Auzmor</h5>
                    <a
                      href="https://auzmor.com/"
                      style={{ textDecoration: "none" }}
                    >
                      <small className="fg-theme">auzmor.com</small>
                    </a>
                    <p>
                      Building Auzmor Office: An employee experience product.
                    </p>
                    <p>
                      Building Auzmor Hire: Powerful simple employee hiring
                      software
                    </p>
                  </div>
                </li>
                <li>
                  <div className="title">2021 - 2022</div>
                  <div className="details">
                    <h5>Freshflows</h5>
                    <a href="https://freshflows.io/">
                      <small className="fg-theme">freshflows.io</small>
                    </a>
                    <p>
                      Built Freshflows.io: Product development tool where an
                      idea can be converted to product.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="title">2019 - 2021</div>
                  <div className="details">
                    <h5>Edukite learning</h5>
                    <a href="https://edukite.co.za/">
                      <small className="fg-theme">edukite.co.za</small>
                    </a>
                    <p>Built 70+ web simulations </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
