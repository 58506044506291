import React from 'react';
import '../style.css';

class Home extends React.Component {
    render() {
        return (
            <div className="vg-page page-home" id="home">
                <div className="navbar navbar-expand-lg navbar-dark sticky" data-offset="500">
                    <div className="container">
                        <button className="navbar-toggler" data-toggle="collapse" data-target="#main-navbar" aria-expanded="true">
                            <span className="ti-menu"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="main-navbar">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <a href="#home" className="nav-link" data-animate="scrolling">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#about" className="nav-link" data-animate="scrolling">About</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#experience" className="nav-link" data-animate="scrolling">Experience</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#project" className="nav-link" data-animate="scrolling">Projects</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#contact" className="nav-link" data-animate="scrolling">Contact</a>
                                </li>
                            </ul>
                            <ul className="nav ml-auto">
                                <li className="nav-item">

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="caption-header text-center wow zoomInDown">
                    <h5 className="fw-normal">Welcome</h5>
                    <h1 className="fw-light mb-4">I'm <b className="fg-theme">Dhruvin</b> Modi</h1>
                    <div className="badge">Software Engineer</div>
                </div>
                <div className="floating-button"><span className="ti-mouse"></span></div>
            </div>
        )
    }
}

export default Home;